<script setup>
import { useDisplay } from 'vuetify/lib/framework.mjs'

useServerSeoMeta({
  title: '', // purposefully left blank so title template in app.vue works as expected
  description: `Experience the heart of faith at First Reformed Church, founded in 1951 and now a vibrant community of Jesus followers. Rooted in the Reformed tradition, our worship seamlessly blends the traditional and contemporary. In 2023, we joined the Reformation Canada Network, embracing a commitment to God's Word in all aspects of life. Join our weekly worship services, where hymns and praise unite in glorifying God. Explore our beliefs in the Triune God, covenants, and the sanctity of life. Find unity in essentials, liberty in non-essentials, and charity in all things as we journey together in faith, love, and service.`,
  ogTitle: 'First Reformed Church of St. Catharines',
  ogDescription: `Experience the heart of faith at First Reformed Church, founded in 1951 and now a vibrant community of Jesus followers. Rooted in the Reformed tradition, our worship seamlessly blends the traditional and contemporary. In 2023, we joined the Reformation Canada Network, embracing a commitment to God's Word in all aspects of life. Join our weekly worship services, where hymns and praise unite in glorifying God. Explore our beliefs in the Triune God, covenants, and the sanctity of life. Find unity in essentials, liberty in non-essentials, and charity in all things as we journey together in faith, love, and service.`,
  ogType: 'website',
  ogUrl: 'https://firstrefchurch.ca',
  ogImage: 'https://firstrefchurch.ca/img/homepage/hero.jpg',
  ogLocale: 'en_US',
  colorScheme: 'only light',
  robots: 'index follow',
})

useJsonld({
  '@context': 'http://schema.org',
  '@type': 'Church',
  'url': 'http://www.firstrefchurch.ca',
  'name': 'First Reformed Church',
  'openingHours': 'Su 10:00-12:00',
  'address': {
    '@type': 'PostalAddress',
    'addressLocality': 'St. Catharines',
    'addressRegion': 'ON',
    'postalCode': 'L2S2Z3',
    'streetAddress': '1136 Vansickle Road N.',
  },
  'geo': {
    '@type': 'GeoCoordinates',
    'latitude': '43.16',
    'longitude': '-79.27',
  },
  'isAccessibleForFree': true,
  'openingHoursSpecification': [
    {
      '@type': 'OpeningHoursSpecification',
      'closes': '12:00:00',
      'dayOfWeek': 'http://schema.org/Sunday',
      'opens': '10:00:00',
    },
  ],
  'photo': 'http://www.firstrefchurch.ca/img/homepage/hero.jpg',
  'image': 'http://www.firstrefchurch.ca/img/homepage/hero.jpg',
  'logo': 'http://www.firstrefchurch.ca/img/logo.png',
  'publicAccess': true,
  'smokingAllowed': false,
  'telephone': '905-685-1441',
  'description': "Experience the heart of faith at First Reformed Church, founded in 1951 and now a vibrant community of Jesus followers. Rooted in the Reformed tradition, our worship seamlessly blends the traditional and contemporary. In 2023, we joined the Reformation Canada Network, embracing a commitment to God's Word in all aspects of life. Join our weekly worship services, where hymns and praise unite in glorifying God. Explore our beliefs in the Triune God, covenants, and the sanctity of life. Find unity in essentials, liberty in non-essentials, and charity in all things as we journey together in faith, love, and service.",
})

const { mdAndUp, smAndUp } = useDisplay()
</script>

<template>
  <div>
    <v-container class="pa-0">
      <div class="hero">
        <nuxt-img
          src="/img/homepage/hero.jpg"
          alt="First Reformed Church"
          style="width:100%; height: 100%; object-fit: cover"
          fit="cover"
          format="webp"
          preload
        />
        <div class="hero-content d-flex h-100 align-center">
          <v-row justify="center" data-aos="fade-up" data-aos-delay="250">
            <v-col cols="12">
              <div class="d-flex flex-column justify-center align-center text-white text-center">
                <span class="text-button">Welcome to</span>
                <h1>First Reformed Church</h1>
                <span class="text-overline">St. Catharines, ON</span>
              </div>
            </v-col>
            <!-- <v-col cols="auto">
                <v-btn to="/faith/what-is-the-gospel">
                  What is the gospel?
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn color="primary" to="/about/plan-your-visit">
                  Plan Your Visit
                </v-btn>
              </v-col> -->
          </v-row>
        </div>
      </div>
    </v-container>

    <!-- Welcome / Stream -->
    <section style="padding-top: 30px; padding-bottom: 60px">
      <v-container>
        <v-row
          align="center"
          justify="space-around"
        >
          <v-col
            lg="5"
            md="6"
            sm="12"
            cols="12"
            :data-aos="smAndUp ? 'fade-right' : 'zoom-in'"
            :data-aos-delay="smAndUp ? 250 : 0"
          >
            <div class="vision">
              <div class="text-overline text-primary">
                Our Vision
              </div>
              <div style="line-height: 1.25;">
                To See Jesus and Together Become More Like Him
              </div>
            </div>

            <div class="mission text-center mt-8 mb-10">
              <div class="text-overline text-primary">
                Our Mission
              </div>
              <div><span class="text-h6">E</span>ncourage members to follow Jesus</div>
              <div><span class="text-h6">E</span>quip followers to serve Jesus</div>
              <div><span class="text-h6">E</span>ngage each other and together be the body</div>
            </div>

            <v-row align="center" justify="center">
              <v-col cols="auto">
                <v-btn color="primary" to="/about/our-story">
                  Our Story
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn color="primary" to="/about/what-we-believe">
                  What We Believe
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            lg="5"
            md="6"
            sm="8"
            cols="12"
            :data-aos="smAndUp ? 'fade-left' : 'zoom-in'"
            data-aos-delay="250"
          >
            <v-responsive :aspect-ratio="16 / 9">
              <LiveStream />
            </v-responsive>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <!-- Join Us -->
    <section class="joinus" data-aos="fade-in">
      <v-container>
        <v-row justify="center">
          <v-col
            xl="5"
            md="6"
            sm="8"
            cols="12"
            order="2"
            order-md="1"
            data-aos="zoom-in"
            data-aos-delay="250"
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d514.4738513983808!2d-79.27212488110904!3d43.161151145503716!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d351977a856fc1%3A0x4ad046a4c2f9229d!2sFirst%20Reformed%20Church%20of%20St.%20Catharines!5e0!3m2!1sen!2sca!4v1669255333090!5m2!1sen!2sca"
              style="width: 100%; height: auto; min-height: 350px"
              class="map"
              title="Map Showing First Reformed Church"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            />
          </v-col>

          <v-col
            xl="7"
            md="6"
            cols="12"
            style="align-self: center"
            order-md="2"
            order="1"
          >
            <div class="heading">
              <div class="mb-3">
                Join us <br> for worship
              </div>

              <div class="text-overline mb-3">
                Sundays at 10 AM
              </div>
            </div>

            <address class="mb-4 text-center">
              1136 Vansickle Road North <br>
              St. Catharines, ON <br>
            </address>

            <v-row justify="center">
              <v-col cols="auto">
                <v-btn to="/about/plan-your-visit">
                  Plan your Visit
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn
                  href="https://www.google.com/maps/dir//First+Reformed+Church+of+St.+Catharines,+1136+Vansickle+Rd+N,+St.+Catharines,+ON+L2S+2Z3/@43.1611823,-79.2736369,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x89d351977a856fc1:0x4ad046a4c2f9229d!2m2!1d-79.271386!2d43.1611995"
                  target="_blank"
                  rel="noopener"
                >
                  Directions
                </v-btn>
              </v-col>
              <!-- <v-col cols="auto">
                <v-btn>
                  Request a Ride
                </v-btn>
              </v-col> -->
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <!-- Get Involved -->
    <section class="getinvolved">
      <v-container>
        <v-row
          align="center"
          justify-sm="center"
          justify-md="space-between"
        >
          <v-col
            cols="12"
            sm="8"
            md="6"
            class="py-6"
            :data-aos="mdAndUp ? 'fade-right' : 'zoom-in'"
          >
            <div class="heading mb-5">
              Get Involved
            </div>

            <p class="text-center pb-3">
              Sign up to receive more information on...
            </p>

            <LazyNewsletterSignup />

            <div class="text-center">
              <small>
                Unsubscribe at any time. Your information will never be shared or sold.
              </small>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="5"
            :data-aos="mdAndUp ? 'fade-left' : 'zoom-in'"
          >
            <EventList />
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section class="pastors">
      <v-container>
        <div class="heading mb-5">
          Meet the Pastors
        </div>
        <v-card
          class="py-5 mb-5"
          variant="flat"
          data-aos="fade-up"
        >
          <v-row>
            <v-col
              cols="12"
              md="auto"
              align="center"
            >
              <v-avatar
                image="/img/homepage/PastorArt.jpg"
                size="160"
              />
            </v-col>
            <v-col
              cols="12"
              md
            >
              <div class="text-h6 pb-2">
                Pastor Art VanSlageren
              </div>
              <div>
                Art is the senior pastor of First Reformed Church. He and his wife Allison have served here since July 1992. They have three adult children, Aaron, BethAnn and Bradley who also worship and serve at FRC. Art received his bachelor’s degree from Reformed Bible College and his masters from Western Theological Seminary. His love for God draws him daily to the Word. His gratitude for saving faith in Jesus Christ motivates his ministry at FRC and beyond. And he acknowledges daily the work of the Holy Spirit to refresh him. Along with a love for study of God’s Word and preaching the good news of Jesus, Art has the joy of teaching Senior and Adult Bile study, leading a Men’s discipleship class and a new members class. Art also enjoys the blessing of leading worship with gifted musicians on Sunday mornings. In his spare time, he enjoys spending time with his family, golf and is a Classic car enthusiast.
              </div>
            </v-col>
          </v-row>
        </v-card>
        <v-card
          class="py-5"
          variant="flat"
          data-aos="fade-up"
        >
          <v-row>
            <v-col
              cols="12"
              md="auto"
              align="center"
            >
              <v-avatar
                image="/img/homepage/PastorQuinton.jpg"
                size="160"
              />
            </v-col>
            <v-col
              cols="12"
              md
            >
              <div class="text-h6 pb-2">
                Pastor Quinton Mol
              </div>
              <div>
                Pastor Quinton has been our Associate Pastor since 2019. He did his undergrad studies at Redeemer University and received his Masters of Divinity from Western Theological Seminary.
                He is passionate about the Word of God, and loves seeing faith be manifested in every area of life. When he is not at home with his wife and children, he loves spending time outdoors either gardening, fishing or hunting.
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </section>
  </div>
</template>

<style scoped lang="scss">
  :deep(.v-btn) {
    text-transform: capitalize;
  }

  :deep(.hero) {
    position: relative;
    height: 55vh;
    clip-path: polygon(0 0, 100% 0, 100% 93%, 0 100%);

    .hero-content {
      position: absolute;
      left:0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.45);
    }
  }

  .vision {
    font-size: max(min(3vw, 30px), 30px);
    line-height: 1;
    text-align: center;
  }

  .joinus {
    background-color: #0072bb;
    color: #fff;
    padding: 30px 0px;
    font-size: 16px;
    .text-overline {
      font-size:18px !important;
    }
    .heading {
      font-size: max(min(5vw, 40px), 30px);
      font-weight: bold;
      text-transform: uppercase;
      line-height: 1;
      text-align: center;
    }
    .map {
      border: 0;
      width: 100%;
      min-height: 100%;
    }
  }

  .getinvolved {
    padding: 50px 0px;
    .heading {
      font-size: max(min(5vw, 40px), 30px);
      font-weight: bold;
      line-height: 1;
      text-align: center;
    }
  }

  .pastors {
    padding-bottom: 50px;
    .heading {
      font-size: max(min(2vw, 40px), 30px);
      font-weight: bold;
      line-height: 1;
      text-align: center;
    }
  }
</style>
